import PropTypes from 'prop-types';
import Icon from '@material-ui/core/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/styles';
import placeholder_illustration from 'assets/img/placeholder_illustration.png';
import AlertDialogSlide from 'common/components/dialog/dialog';
import GeneralGrid from 'common/components/layout/grid/GeneralGrid';
import LoadingProgress from 'common/components/progress/loading';
import styles from 'material-dashboard-assets/jss/material-dashboard-react/views/dashboardStyle.js';
import Card from 'material-dashboard-components/Card/Card.js';
import CardFooter from 'material-dashboard-components/Card/CardFooter.js';
import CardHeader from 'material-dashboard-components/Card/CardHeader.js';
import CardIcon from 'material-dashboard-components/Card/CardIcon.js';
import GridContainer from 'material-dashboard-components/Grid/GridContainer.js';
import GridItem from 'material-dashboard-components/Grid/GridItem.js';
import { getActionResults } from 'modules/owner/pages/actions/activeActions/activeActionsScripts.js';
import React, { Component } from 'react';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { Button, Typography } from '@material-ui/core';
import { ColumnsBelowSearch, NumberGenericElement } from 'common/components/table/tableComponents';
import Table from 'common/components/table/table-material';
import VoucherTable from './VoucherTable';
import UserDetails from '../../userDetails/UserDetails';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { getListall } from '../../validators/validatorsActions.js';
import * as XLSX from 'xlsx';
import { ro } from 'date-fns/locale';
const { format } = require('date-fns');

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = { userSelected: {}, openDetails: false };
    this.props.getActionResults(this.props.location.query.action);
    this.props.getListall(this.props.auth.user.establishments[0].stations[0].id);
    this.closeDetails = this.closeDetails.bind(this);
  }

  converterParaCSV = (listaDeInformacao, validador) => {
    let j = 0;
    let contador = 0;
    if (!Array.isArray(listaDeInformacao) || listaDeInformacao.length === 0) {
      console.error('Lista de objetos vazia ou inválida.');
      return '';
    }
    // Obtém os cabeçalhos do CSV a partir das chaves selecionadas
    const cabe = ['Nome', 'Voucher', 'Emissão', 'Validador', 'Validação'];

    // Gera as linhas do CSV
    const linhas = [];
    for (let i = 0; i < listaDeInformacao.length; i++) {
      const user = listaDeInformacao[i];
      for (let a = 0; a < user.presential_course_subscriptions.length; a++) {
        const pcs = user.presential_course_subscriptions[a];
        const createdat = new Date(pcs.createdAt);
        const validation_date = new Date(pcs.validation_date);

        linhas[`${j}`] = [];
        linhas[j].push(user.name);
        linhas[j].push(pcs.code);
        linhas[j].push(format(createdat, 'dd/MM/yyyy HH:mm:ss'));
        linhas[j].push(this.Validador(validador, pcs.code_validator_id));
        if (pcs.validated === '1') {
          linhas[j].push(format(validation_date, 'dd/MM/yyyy HH:mm:ss'));
          contador++;
        } else {
          linhas[j].push(pcs.validation_date);
        }
        j++;
      }
    }
    linhas[`${j}`] = [];
    linhas[j].push(contador);
    const linhasCSV = linhas.map((linha) => linha.join(','));
    const csv = [cabe.join(','), ...linhasCSV].join('\n');

    return csv;
  };

  gerarExcel = (csvString, titulo, actionName = '') => {
    try {
      // Parse da string CSV para um array de arrays
      const nomeAtividade =
        titulo[0]?.presential_course_subscriptions[0]?.presential_course?.action?.name ||
        actionName;
      const dataAtual = new Date();
      const dia = dataAtual.getDate();
      const mes = dataAtual.getMonth() + 1; // Adicione 1 porque os meses começam do zero
      const ano = dataAtual.getFullYear();
      const csvData = csvString.split('\n').map((row) => row.split(','));
      // Crie um workbook do Excel
      const workbook = XLSX.utils.book_new();
      // Crie uma planilha a partir dos dados CSV
      const worksheet = XLSX.utils.aoa_to_sheet(csvData);
      // Adicione a planilha ao workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
      // Salve o workbook como um arquivo Excel
      XLSX.writeFile(workbook, `relatorio_${nomeAtividade}_${dia}_${mes}_${ano}.xlsx`);
    } catch (error) {
      toastr.error('Erro', 'Não foi possível exportar o relatório.');
      console.error('Não foi possível gerar o relatório.', error);
    }
  };

  Validador = (validador, code) => {
    for (let b = 0; b < validador.length; b++) {
      if (code === validador[b].id) {
        return validador[b].identification;
      }
    }
    return ' ';
  };

  gerarPDF = (csvData, titulo, actionName = '') => {
    try {
      const dataAtual = new Date();
      const dia = dataAtual.getDate();
      const mes = dataAtual.getMonth() + 1; // Adicione 1 porque os meses começam do zero
      const ano = dataAtual.getFullYear();
      const doc = new JsPDF({ orientation: 'landscape' });
      const nomeAtividade =
        titulo[0]?.presential_course_subscriptions[0]?.presential_course?.action?.name ||
        actionName;

      const options = {
        margin: { top: 5 },
        headStyles: { fontSize: 10 }, // Tamanho da fonte para o cabeçalho
        bodyStyles: { fontSize: 9 }, // Tamanho da fonte para o corpo
        startY: 20,
      };
      // Analise a string CSV para obter os dados da tabela
      const rows = csvData.split('\n').map((row) => row.split(','));
      const nome = rows[1];
      let validados = rows[rows.length - 1];
      validados = parseInt(validados[0]);
      let gerados = rows.length - 2;
      rows.pop();
      // Adicione o título
      doc.text(`Relatorio ${nomeAtividade}, ${dia}/${mes}/${ano}`, 14, 10);
      // Crie a tabela
      doc.autoTable({
        head: [rows[0]], // Cabeçalho da tabela
        body: rows.slice(1), // Corpo da tabela
        ...options,
      });
      let texto = `${validados} Validados : Litros ${validados * 20}\n${gerados} Gerados : Litros ${
        gerados * 20
      }`;
      doc.setFontSize(10);
      doc.text(texto, 14, doc.lastAutoTable.finalY + 10);

      // Salve o PDF
      doc.save(`relatorio_${nomeAtividade}_${dia}_${mes}_${ano}.pdf`);
    } catch (error) {
      toastr.error('Erro', 'Não foi possível exportar o relatório.');
      console.error('Não foi possível gerar o relatório.', error);
    }
  };

  onDialogClose = () => {
    if (this.props.location.query.from === 'inicio') {
      this.props.router.push('/owner/acoes/inicio');
    } else {
      this.props.router.push(
        `/owner/acoes/minhas-atividades/?to=${this.props.location.query.from}`
      );
    }
  };

  renderEmptyScreen() {
    return (
      <AlertDialogSlide
        title="Resultados"
        visible
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'sm'}>
        <section id="statement-buy-points" style={{ height: '100vh' }}>
          <GeneralGrid container spacing={3} alignItems="center">
            <GeneralGrid item xs={12} md={6}>
              <GeneralGrid container spacing={3}>
                <GeneralGrid item xs={12}>
                  <h2 className="signup-text">Ninguém participou dessa atividade até o momento</h2>
                  <p>Aqui você poderá distribuir os prêmios</p>
                </GeneralGrid>
              </GeneralGrid>
            </GeneralGrid>
            <GeneralGrid item xs={12} md={6}>
              <img alt="" src={placeholder_illustration} className="img-fluid" />
            </GeneralGrid>
          </GeneralGrid>
        </section>
      </AlertDialogSlide>
    );
  }

  openDetails(rowData) {
    this.setState((state) => ({
      ...state,
      userSelected: rowData,
      openDetails: true,
    }));
  }

  closeDetails() {
    this.setState((state) => ({
      ...state,
      openDetails: false,
    }));
  }

  renderBackground = () => '#3f51b5';

  render() {
    const { classes } = this.props;
    const { actionResults, loadingActionResults, actionDetail } = this.props.activeActions;
    const { impressions, reach, participations, cashback, cashback_left, usersReached } =
      actionResults;
    const validador = this.props.validatorCode.list.content;
    const CSVprint = this.converterParaCSV(usersReached, validador);
    if (loadingActionResults) {
      return (
        <AlertDialogSlide
          title="Resultados"
          visible
          height="95vh"
          onClose={this.onDialogClose}
          maxWidth={isMobile ? 'xl' : 'md'}>
          <div className="d-flex justify-content-center mt-5">
            <LoadingProgress />
          </div>
        </AlertDialogSlide>
      );
    }

    return (
      <AlertDialogSlide
        isFullScreen={isMobile}
        title={`${actionDetail.name} (${moment(actionDetail.start).format('DD/MM')} - ${
          moment(actionDetail.end).isAfter(new Date())
            ? 'Hoje'
            : moment(actionDetail.end).format('DD/MM')
        }) `}
        visible
        height="95vh"
        onClose={this.onDialogClose}
        maxWidth={isMobile ? 'xl' : 'md'}>
        <Typography variant="h6">Resultados gerais</Typography>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">remove_red_eye</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Impressões</p>
                <h3 className={classes.cardTitle}>{impressions}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de visualizações que sua atividade teve, independente se for do mesmo usuário ou de usuários diferentes."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">people_alt</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Alcance</p>
                <h3 className={classes.cardTitle}>{reach}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participantes que viram sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">call_made</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Participações</p>
                <h3 className={classes.cardTitle}>{participations.length}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a quantidade de participações efetivas na sua atividade."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">bar_chart</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Conversão</p>
                <h3 className={classes.cardTitle}>
                  {parseFloat((participations.length / reach) * 100).toFixed(1)}%
                </h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="É a porcentagem de pessoas que participaram dessa atividade em relação a todas que foram alcançadas"
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">attach_money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Gasto atual</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é quantidade de dinheiro que já foi paga em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card style={{ position: 'relative' }} className="active-actions">
              <CardHeader color="warning" stats icon>
                <CardIcon color="white">
                  <Icon color="primary">money</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Cashback restante</p>
                <h3 className={classes.cardTitle}>R${parseFloat(cashback_left).toFixed(2)}</h3>
              </CardHeader>
              <CardFooter stats style={{ position: 'absolute', bottom: 5, width: '90%' }}>
                <div className={classes.stats} style={{ cursor: 'pointer' }}>
                  <Tooltip
                    style={{ marginLeft: 10 }}
                    title="Essa é a quantidade de dinheiro que foi reservada para ação mas ainda não foi distribuida em cashback."
                    placement="top-start">
                    <Icon color="default">help</Icon>
                  </Tooltip>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <GeneralGrid container spacing={3}>
          <GeneralGrid item xs={12}>
            {!_.isEmpty(usersReached) && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  <button
                    onClick={() => this.gerarPDF(CSVprint, usersReached, actionDetail.name)}
                    aria-label="Gerar PDF">
                    <FontAwesomeIcon
                      icon={faFilePdf}
                      style={{ color: 'red', fontSize: '25px', marginLeft: '845px' }}
                    />
                  </button>
                </div>
                <div>
                  <button
                    onClick={() => this.gerarExcel(CSVprint, usersReached, actionDetail.name)}
                    aria-label="Gerar Excel">
                    <FontAwesomeIcon icon={faBook} style={{ color: 'green', fontSize: '25px' }} />
                  </button>
                </div>
              </div>
            )}
            <Table
              searchPlaceholder={'Procurar por nome de usuário'}
              hasToolbar={true}
              defaultSelected=""
              title="Extrato"
              columns={[
                { field: 'name', title: 'Nome' },
                {
                  field: 'quantity',
                  title: 'Visualizações',
                  render: (rowData) => (
                    <NumberGenericElement category="visualizations" value={rowData.quantity} />
                  ),
                },
                {
                  title: 'Participação',
                  render: (rowData) =>
                    rowData.user_actions.length > 0 ? 'Visualizou e participou' : 'Visualizou',
                },
                {
                  title: 'Email',
                  field: 'email',
                },
                {
                  title: 'Detalhes',
                  field: 'search',
                  render: (rowData) => (
                    <ColumnsBelowSearch
                      buttons={{
                        namedButtons: [
                          {
                            name:
                              rowData.user_actions.length > 0 ? (
                                <Button
                                  variant="contained"
                                  onClick={() => this.openDetails(rowData)}>
                                  Ver detalhes
                                </Button>
                              ) : (
                                'Ainda não participou'
                              ),
                            onClick: () => {},
                            disabled: true,
                          },
                        ],
                        iconButtons: [],
                      }}
                    />
                  ),
                },
              ]}
              rows={usersReached}
              toolbarSearch
              mobileTable={isMobile}
              hasBorder={!isMobile}
            />
          </GeneralGrid>
          <GeneralGrid item spacing={3}>
            <VoucherTable />
          </GeneralGrid>
        </GeneralGrid>
        <UserDetails
          open={this.state.openDetails}
          data={this.state.userSelected}
          onClose={this.closeDetails}
          actionType={this.props.activeActions.actionDetail.type_id}></UserDetails>
      </AlertDialogSlide>
    );
  }
}

Results.propTypes = {
  activeActions: PropTypes.shape({
    actionResults: PropTypes.shape({
      action: PropTypes.any,
      cashback: PropTypes.any,
      cashback_left: PropTypes.any,
      impressions: PropTypes.any,
      participations: PropTypes.shape({
        length: PropTypes.any,
      }),
      reach: PropTypes.any,
      usersReached: PropTypes.any,
    }),
    loadingActionResults: PropTypes.any,
    actionDetail: PropTypes.shape({
      end: PropTypes.any,
      name: PropTypes.any,
      start: PropTypes.any,
    }),
  }),
  classes: PropTypes.shape({
    cardCategory: PropTypes.any,
    cardTitle: PropTypes.any,
    stats: PropTypes.any,
  }),
  getActionResults: PropTypes.func,
  location: PropTypes.shape({
    query: PropTypes.shape({
      action: PropTypes.any,
      from: PropTypes.string,
    }),
  }),
  router: PropTypes.shape({
    push: PropTypes.func,
  }),
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  activeActions: state.activeActions,
  validatorCode: state.validatorCode,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getActionResults,
      getListall,
    },
    dispatch
  );

const StyledResults = withStyles(styles)(Results);

export default connect(mapStateToProps, mapDispatchToProps)(StyledResults);
